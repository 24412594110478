import Activity from "../Activities/Activity";
import ComputeAllocationRequest from "./Models/ComputeAllocationRequest";
import ComputeAllocationWalletResponse from "./Models/ComputeAllocationResponse";
import Field from "../DynamicEntity/Field";
import { ImportErrorResponse } from "./Models/ImportErrorResponse";
import ImportResponse from "./Models/ImportResponse";
import Me from "./Me";
import UpdateUserProfile from "./UpdateUserProfile";
import User from "./User";
import UserServiceDashboard from "./UserDashboardProfile";
import WalletLink from "../Organizations/WalletLink";
import WorkindApiClient from "../WorkindApiClient";
import dayjs from "dayjs";
import mockUserService from "../../mock/mockUserService";

class UserService extends WorkindApiClient {
  async getMe(): Promise<Me | undefined> {
    if (process.env.REACT_APP_API_MOCK === "1") {
      return await mockUserService.getMe();
    } else {
      const me = await this.get<Me>(`${this.apiUrl}/users/me`);

      if (me) {
        for (const org of me.organizations) {
          for (const table of org.tables) {
            table.fields = new Map<string, Field>(Object.entries(table.fields));
          }
        }
      }

      return me;
    }
  }

  async getWalletLinks(organizationWalletId: string): Promise<Array<WalletLink>> {
    const list = await this.get<Array<WalletLink>>(`${this.apiUrl}/users/me/links?organizationWalletId=${organizationWalletId}`);
    return list || [];
  }

  async ActivateUserProfile(organizationId: number, userId: number): Promise<User | undefined> {
    try {
      return await this.post(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/activate`, null);
    } catch {
      return undefined;
    }
  }

  async DeactivateUserProfile(organizationId: number, userId: number): Promise<User | undefined> {
    try {
      return await this.post(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/deactivate`, null);
    } catch (error) {
      return undefined;
    }
  }

  async getUserDashboard(): Promise<UserServiceDashboard | undefined> {
    if (process.env.REACT_APP_API_MOCK === "1") {
      return await mockUserService.getUserDashboard();
    } else {
      return await this.get<UserServiceDashboard>(`${this.apiUrl}/users/me/dashboard`);
    }
  }

  async getUserProfile(organizationId: number, userId: string): Promise<User | undefined> {
    const profile = await this.get<User>(`${this.apiUrl}/organizations/${organizationId}/users/${userId}`);
    if (!profile) return undefined;

    return {
      ...profile,
      disabledDate: profile.disabledDate ? dayjs(profile.disabledDate) : undefined,
      expirationDate: profile.expirationDate ? dayjs(profile.expirationDate) : undefined,
      updatedAt: profile.updatedAt ? dayjs(profile.updatedAt) : undefined,
      lastLogin: profile.lastLogin ? dayjs(profile.lastLogin) : undefined,
      attributes: new Map<string, string>(Object.entries(profile.attributes)),
    };
  }

  async getUserActivities(organizationId: number, userId: number): Promise<Array<Activity>> {
    const activities = await this.get<Array<Activity>>(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/activities`);
    if (!activities) return [];

    return activities.map((activity: Activity) => {
      return {
        ...activity,
        createdOn: new Date(activity.createdOn),
      };
    });
  }

  async addUserBankData(organizationId: string, userId: string, userBankData: any) {
    await this.put(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/bankaccounts`, userBankData);
  }

  async getUsersOfOrganization(organizationId: number): Promise<Array<User>> {
    const users = await this.get<Array<User>>(`${this.apiUrl}/organizations/${organizationId}/users`);
    if (!users) return [];
    return users;
  }

  async updateUserProfile(organizationId: number, userProfile: User) {
    try {
      const attributesObject = Object.fromEntries(userProfile.attributes);
      const data: UpdateUserProfile = {
        userId: userProfile.id,
        email: userProfile.email,
        firstname: userProfile.firstname,
        lastname: userProfile.lastname,
        phoneNumber: userProfile.mobile,
        referenceId: userProfile.referenceId,
        language: userProfile.language,
        attributes: attributesObject,
      };
      await this.put(`${this.apiUrl}/organizations/${organizationId}/users`, data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async computeAllocation(organizationId: number, userId: number): Promise<ComputeAllocationWalletResponse[]> {
    try {
      const data: ComputeAllocationRequest = {
        userId: userId,
      };
      const response = await this.post<ComputeAllocationRequest, ComputeAllocationWalletResponse[]>(
        `${this.apiUrl}/organizations/${organizationId}/allocations/compute`,
        data
      );
      if (!response) return [];

      for (const r of response) {
        if (r.walletAllocation) {
          r.walletAllocation.eligibleDate = r.walletAllocation.eligibleDate ? new Date(r.walletAllocation.eligibleDate) : undefined;
        }
      }
      return response;
    } catch {
      return [];
    }
  }

  async updateAllocation(organizationId: number, userId: number): Promise<ComputeAllocationWalletResponse[]> {
    try {
      const data: ComputeAllocationRequest = {
        userId: userId,
      };
      const response = await this.post<ComputeAllocationRequest, ComputeAllocationWalletResponse[]>(
        `${this.apiUrl}/organizations/${organizationId}/allocations/update`,
        data
      );
      if (!response) return [];

      for (const r of response) {
        if (r.walletAllocation) {
          r.walletAllocation.eligibleDate = r.walletAllocation.eligibleDate ? new Date(r.walletAllocation.eligibleDate) : undefined;
        }
      }
      return response;
    } catch {
      return [];
    }
  }

  async importUsers(organizationId: number, executeImport: boolean, file: File): Promise<ImportResponse | ImportErrorResponse> {
    if (organizationId < 0) {
      throw new Error("Invalid organizationId.");
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await this.post<FormData, ImportResponse | undefined>(
        `${this.apiUrl}/organizations/${organizationId}/users/import?executeImport=${executeImport}`,
        formData
      );

      if (response) {
        return response;
      }

      throw new Error("Unexpected response format.");
    } catch (error: any) {
      if (error.response?.data?.code === "VALIDATION_ERROR") {
        return {
          code: error.response.data.code,
          message: error.response.data.message,
          details: error.response.data.details,
        };
      }

      throw error;
    }
  }
}

const userService = new UserService();
export default userService;
