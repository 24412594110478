import WorkindApiClient from "../WorkindApiClient";

class ReportService extends WorkindApiClient {
  async uploadReportFile(
    organizationId: number,
    DataFile: FormData,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined
  ): Promise<Boolean | undefined> {
    try {
      const result = await this.post(`${this.apiUrl}/organizations/${organizationId}/reports/pay/upload?dateStart=${dateStart}&dateEnd=${dateEnd}`, DataFile);
      return true;
    } catch {
      return false;
    }
  }

  async downloadReportFile(
    organizationId: number,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined,
    fileName: string | undefined = undefined
  ): Promise<String | undefined> {
    try {
      const result = await this.get<String>(
        `${this.apiUrl}/organizations/${organizationId}/reports/pay/download?dateStart=${dateStart}&dateEnd=${dateEnd}&fileName=${fileName}`
      );
      return result;
    } catch {
      return undefined;
    }
  }

  async listReportFile(organizationId: number, dateEnd: string | undefined = undefined): Promise<Array<String> | undefined> {
    try {
      const response = await this.get<Array<String>>(`${this.apiUrl}/organizations/${organizationId}/reports/pay/list?dateEnd=${dateEnd}`);
      return response;
    } catch {
      return undefined;
    }
  }

  async generateReportFile(
    organizationId: number,
    dateStart: string | undefined = undefined,
    dateEnd: string | undefined = undefined
  ): Promise<unknown | undefined> {
    try {
      const result = await this.post(`${this.apiUrl}/organizations/${organizationId}/reports/pay/generate?dateStart=${dateStart}&dateEnd=${dateEnd}`, null);
      return result;
    } catch {
      return undefined;
    }
  }
}

const reportService = new ReportService();
export default reportService;
