import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import reportService from "../../services/Reports/ReportService";
import { Notify } from "../../components/notify";
import { Dayjs } from "dayjs";
import DateRangeSelector from "../../components/DateRangeSelector";
import { DateHelpers } from "../../services/DateHelpers";
import { Link, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { DownloadHelpers } from "../../components/DownloadHelpers";

const Input = styled("input")({
  display: "none",
});

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export function EmployeeReporsPage() {
  const { t } = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState<File | null>(null);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(DateHelpers.nowDateOnly().add(-30, "day"));
  const [dateTo, setDateTo] = useState<Dayjs | null>(DateHelpers.nowDateOnly());
  const [reportFiles, setReportFiles] = useState<Array<String>>([]);
  const [dateIsDisable, setDateIsDisable] = useState<boolean>(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);
  const [upload, setUpload] = useState(true);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const { organizationId } = useParams<string>();
  const [organizationSelected, setOrganizationSelected] = useState<number>(organizationId ? parseInt(organizationId) : -1);

  useEffect(() => {
    if (organizationSelected <= 0 || organizationId == undefined) {
      setOrganizationSelected(20);
    }

    listReportFile();
  }, [dateTo, organizationSelected]);

  const handleClickOpenDialogConfirm = () => {
    setOpenDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type === "text/csv") {
        setUploadedFiles(e.target.files[0]);
        setUpload(false);
      } else {
        Notify.error(t("reports.msg-error-csvfile"));
      }
    }
  };
  // function to csv download #data : the data that will be inserted in the file #fileName : the name of the file that will be genereted

  const handleDownloadReportFile = async (p: string) => {
    try {
      if (organizationSelected >= 0) {
        if (p.search("AuditTransactions") > -1) {
          const result = await reportService.downloadReportFile(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString(), "AuditTransactions");
          if (result !== undefined) {
            DownloadHelpers.downloadCsvFile(result as string, p.substring(32));
          }
        } else if (p.search("PaieRemboursements") > -1) {
          const result = await reportService.downloadReportFile(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString(), "PaieRemboursements");
          if (result !== undefined) {
            DownloadHelpers.downloadCsvFile(result as string, p.substring(32));
          }
        } else if (p.search("PaieRemboursements-summary") > -1) {
          const result = await reportService.downloadReportFile(
            organizationSelected,
            dateFrom?.toISOString(),
            dateTo?.toISOString(),
            "PaieRemboursementsSummary"
          );
          if (result !== undefined) {
            DownloadHelpers.downloadCsvFile(result as string, p.substring(32));
          }
        } else if (p.search("PaieTransactions") > -1) {
          const result = await reportService.downloadReportFile(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString(), "PaieTransactions");
          if (result !== undefined) {
            DownloadHelpers.downloadCsvFile(result as string, p.substring(32));
          }
        } else if (p.search("QC") > -1) {
          const result = await reportService.downloadReportFile(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString(), "QcFile");
          if (result !== undefined) {
            DownloadHelpers.downloadTxtFile(result as string, p.substring(32));
          }
        }
      }
    } catch (error) {
      Notify.error(error + "");
    }
  };

  const cancelOperation = () => {
    setUpload(true);
    setIsUploadDisabled(false);
    listReportFile();
    setDateIsDisable(false);
  };

  const handleUpload = async () => {
    if (uploadedFiles) {
      const formData = new FormData();
      formData.append("files", uploadedFiles);
      try {
        if (organizationSelected >= 0) {
          const result = await reportService.uploadReportFile(organizationSelected, formData, dateFrom?.toISOString(), dateTo?.toISOString());
          if (result) {
            Notify.success(t("reports.msg-success-fileuploaded"));
            setDateIsDisable(true);
            listReportFile();
            setIsUploadDisabled(true);
            setDateIsDisable(true);
            setOpenDialogConfirm(false);
          } else Notify.error(t("reports.msg-error-fileuploaded"));
        }
      } catch (error) {
        Notify.error(error + "");
      }
    }
  };

  const listReportFile = async () => {
    try {
      if (organizationSelected >= 0) {
        const result = await reportService.listReportFile(organizationSelected, dateTo?.toISOString());
        if (result !== undefined) {
          setReportFiles(result as string[]);
        } else {
          setReportFiles([]);
          console.error(t("reports.msg-error-undefined"));
        }
      }
    } catch (error) {
      Notify.error(error + "");
    }
  };

  const handleGenReportFile = async () => {
    try {
      if (organizationSelected >= 0) {
        const result = await reportService.generateReportFile(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString());
        if (
          result === "Generated AuditTransactions\r\nGenerated PaieTransactions\r\nGenerated PaieRemboursementsSummary\r\n" ||
          result === "Generated AuditTransactions\nGenerated PaieTransactions\nGenerated PaieRemboursementsSummary\n"
        ) {
          Notify.success(t("reports.msg-success-filegen"));
          listReportFile();
        } else Notify.error(t("reports.msg-error-filenotgen"));
      }
    } catch (error) {
      Notify.error(error + "");
    }
  };

  return (
    <>
      <Dialog open={openDialogConfirm} onClose={handleCloseDialogConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t("reports.title-dialog-confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("reports.msg-dialog-confirm")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogConfirm}>{t("reports.btn-dialog-cancel")}</Button>
          <Button onClick={handleUpload} autoFocus>
            {t("reports.btn-dialog-confirmit")}
          </Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h2">{t("reports.label-reports")}</Typography>{" "}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography>{t("reports.label-txt-reports")}</Typography>{" "}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        {upload && (
          <label htmlFor="contained-button-file">
            <Input accept="text/csv" id="contained-button-file" type="file" onChange={handleFileChange} />
            <Button variant="contained" component="span">
              {t("reports.btn-txt-chosefile")}
            </Button>
          </label>
        )}
        {!upload && (
          <Button variant="contained" onClick={cancelOperation}>
            {t("reports.btn-txt-cancelit")}
          </Button>
        )}

        <Stack direction="row" alignItems="center" spacing={2}>
          <DateRangeSelector
            isDisabled={dateIsDisable}
            onChangeFrom={(date) => {
              setDateFrom(date);
            }}
            onChangeTo={(date) => {
              setDateTo(date);
            }}
          />
        </Stack>

        <Button variant="contained" onClick={handleClickOpenDialogConfirm} disabled={isUploadDisabled}>
          {t("reports.btn-txt-upload")}
        </Button>

        <Button variant="contained" onClick={handleGenReportFile}>
          {t("reports.btn-txt-genrepo")}
        </Button>
      </Stack>

      <Stack direction="row" flex={1}>
        <Grid item xs={12} md={12} flex={1}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {t("reports.label-output-reports")}
          </Typography>
          <Demo>
            <List>
              {reportFiles.map((value, index) => (
                <Link
                  key={index}
                  to={""}
                  onClick={() => {
                    handleDownloadReportFile(value as string);
                  }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={value} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Demo>
        </Grid>
      </Stack>
    </>
  );
}
